
export const PENDING = "incomplete";

export const CANCELLED = "cancelled";

export const ACTIVE = "active";

export const RUN_TILL_END = "run_till_end";

export const PAYMENT_FAILED = "payment_failed";

export const EXPIRED = "expired";

export const SUBSCRIPTION_STATES = {
  PENDING,
  CANCELLED,
  ACTIVE,
  PAYMENT_FAILED,
};
