import { CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { SITE_URL } from "../config";
import useMounted from "../hooks/useMounted";

const getExistingConsent = () => {
  try {
    return (
      JSON.parse(localStorage.getItem("cookieConsent")) ||
      JSON.parse(
        localStorage.getItem(
          "metomic-consented-pol:bfe99801-3ec6-4890-9116-25f067c01340"
        )
      )?.true ||
      null
    );
  } catch (error) {
    return null;
  }
};

const CookieConsent = ({ consents }) => {
  const [existingConsent, setExistingConsent] = useState(false);
  const [autoConsent, setAutoConsent] = useState(false);

  const mounted = useMounted();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (mounted) {
      try {
        if (localStorage) {
          const newExistingConsent = getExistingConsent();
          setAutoConsent(newExistingConsent);
          setExistingConsent(newExistingConsent);
          if (newExistingConsent === null) {
            const timeout = setTimeout(() => {
              setVisible(true);
            }, 1000);
            return () => clearTimeout(timeout);
          }
        }
      } catch (error) {
        //
      }
    }
  }, [mounted]);

  const handleReject = () => {
    try {
      if (mounted && localStorage) {
        localStorage.setItem("cookieConsent", JSON.stringify(false));
        setVisible(false);
      }
    } catch (error) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (existingConsent) {
      consents.forEach((consent) => {
        consent.activate();
      });
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [existingConsent]);

  const handleAccept = () => {
    if (mounted) {
      try {
        localStorage.setItem("cookieConsent", JSON.stringify(true));
      } catch (error) {
        //
      }
      setExistingConsent(true);
    }
  };

  const generateClassName = () => {
    if (visible) {
      return "md:mb-8 opacity-100";
    }
    if (existingConsent) {
      return "mb-m100vw opacity-0";
    }
    return "-mb-m100vw invisible opacity-0";
  };

  if (!consents?.length) {
    return null;
  }
  return (
    <div
      style={{ zIndex: 2147483004 }}
      className={`${generateClassName()} transition-cookies duration-700 md:mr-8 bottom-0 right-0 fixed `}
    >
      <div className="md:max-w-sm w-full shadow-lg">
        <div className="border rounded-lg border-gray-200 overflow-hidden bg-white bg-opacity-100 shadow-2xl p-6">
          <h3 className="text-xl font-medium">Can we store cookies?</h3>
          <p className="opacity-80 text-sm my-4">
            We'll use them for our own statistics and to get in touch with you
            for support. As in our{" "}
            <a
              className="underline"
              href={`https://${SITE_URL}/cookies-policy`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Cookie Policy
            </a>
            . Ironically, we'll store your choice as a cookie.
          </p>
          <div className="flex justify-end">
            {!existingConsent && (
              <button
                type="button"
                onClick={handleReject}
                className="px-4 py-2 mr-4 hover:bg-gray-200 hover:bg-opacity-70 opacity-70 rounded-full"
              >
                No, thanks
              </button>
            )}

            <button
              type="button"
              onClick={handleAccept}
              className="text-primary bg-accent transition-all font-semibold px-4 py-2 hover:bg-opacity-80 rounded-full"
            >
              {existingConsent ? (
                <CheckOutlined className="h-6" />
              ) : (
                "OK, whatever"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CookieConsent.propTypes = {
  consents: PropTypes.arrayOf(
    PropTypes.shape({
      activate: PropTypes.func,
    })
  ).isRequired,
};

export default CookieConsent;
