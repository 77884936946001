import {
  CheckCircleOutlined,
  PlusSquareOutlined,
  PoundCircleOutlined,
} from "@ant-design/icons";
import { message, Switch, Tooltip } from "antd";
import { useContext } from "react";
import OrgContext from "./orgContext";
import { SITE_NAME } from "../config";
import { fundingFeatures } from "../constants/features";
import { updateOrganisation } from "../methods/organisations";

export const defaultFeatures = ["insights", "calendar", "people", "tags"];

const extraFeatures = [
  "resources",
  "coronavirus",
  "partners",
  "surveys",
  "payments",
  "funding",
  "checkInCustomers",
  "foodChoices",
];

const details = {
  search: {
    name: "Search",
    description: "Search for people, tags, and events",
  },
  insights: {
    name: "Reporting",
    description: "Automatically create reports from your data",
  },
  calendar: {
    name: "Calendar",
    description: "Create a timetable of sessions, take registers and bookings",
  },
  people: {
    name: "People",
    description: "Manage your members and volunteers",
  },
  tags: {
    name: "Tags",
    description: "Create tags to organise your data",
  },
  coronavirus: {
    name: "Volunteer Requests",
    description: "Create volunteer requests and manage volunteers",
  },
  partners: {
    name: "Partners",
    description: "Manage your partners and their data",
  },
  surveys: {
    name: "Surveys/Outcomes",
    description: "Create surveys and collect responses",
  },
  payments: {
    name: "Payments",
    description: "Track and reconcile payments from your members",
  },
  funding: {
    name: "Funding",
    description:
      "View your previous grant applications and responses to funders",
  },
  checkInCustomers: {
    name: "Check-in members",
    description: "Check-in members at the front door",
  },
  foodChoices: {
    name: "Food stock",
    description: "Manage food stock",
  },
  grantWriter: {
    name: "Grant Writer",
    description: "Automatically generate grant applications",
  },
  documents: {
    name: "Documents",
    description: "Store documents and ask questions about them",
  },
  grantSearch: {
    name: "Grant Search",
    description: "Search for grants that match your criteria",
  },
  resources: {
    name: "Room Bookings",
    description: "Create Rooms, attach them to events, and take bookings for them through Plinth",
  }
};

const FeatureSelection = () => {
  const { organisation } = useContext(OrgContext);

  const { features = [] } = organisation;

  const handleFeatureChange = async (e) => {
    const { name, checked } = e;

    try {
      const newFeatures = checked
        ? [...features, name]
        : features.filter((feature) => feature !== name);
      updateOrganisation(organisation._id, { features: newFeatures });
    } catch (error) {
      console.log(error);
      message.error(
        "Failed to update features, please try again or contact support."
      );
    }
  };

  return (
    <div className="w-full flex min-h-screen p-8 pb-32">
      <div className="bg-white shadow-lg p-8 border border-gray-200 rounded-lg mx-auto max-w-xl w-full ">
        <h2 className="font-medium text-lg mt-0 mb-2 flex items-center  space-x-4 justify-between">
          <div>
            <PoundCircleOutlined className="mr-2" /> Funding Features
          </div>
          <img src="/pippin.png" className="h-12" />
        </h2>
        <p className="mt-1 mb-4 text-gray-700">
          These features are helpful for managing your funding applications and
          reporting.
        </p>
        <div className="w-full mb-8">
          {fundingFeatures
            // .filter((feature) => feature.isAIFeature)
            .map(({ id: feature }) => (
              <div
                key={feature}
                className="flex items-center border-b border-gray-200 py-2"
              >
                <Switch
                  onChange={(checked) =>
                    handleFeatureChange({ name: feature, checked })
                  }
                  type="checkbox"
                  checked={features.includes(feature)}
                  className="mr-4"
                />
                <div>
                  <p className="my-0 font-medium">{details[feature].name}</p>
                  <p className="text-gray-500 text-sm my-0">
                    {details[feature].description}
                  </p>
                </div>
              </div>
            ))}
        </div>
        <h2 className="font-medium text-lg mt-0 mb-0 flex items-center">
          <CheckCircleOutlined className="mr-2" /> Default Features
        </h2>
        <p className="mt-1 mb-4 text-gray-700">
          These features are enabled by default for all accounts. They&apos;re
          the best option for getting started with {SITE_NAME}.
        </p>
        <div className="w-full">
          {defaultFeatures.map((feature) => {
            return (
              <div
                key={feature}
                className="flex items-center border-b border-gray-200 py-2"
              >
                <Switch
                  onChange={(checked) =>
                    handleFeatureChange({ name: feature, checked })
                  }
                  type="checkbox"
                  checked={features.includes(feature)}
                  disabled={features.includes(feature)}
                  className="mr-4"
                />
                <div>
                  <p className="my-0 font-medium">{details[feature].name}</p>
                  <p className="text-gray-500 text-sm my-0">
                    {details[feature].description}
                  </p>
                </div>
              </div>
            );
          })}
          <h2 className="font-medium text-lg mt-8 mb-0 flex items-center">
            <PlusSquareOutlined className="mr-2" /> Advanced Features{" "}
            <Tooltip title="These are free to use, but are not as polished as the default features.">
              <div className="rounded-md bg-blue-100 text-blue-800 px-2 ml-2 text-xs">
                BETA
              </div>
            </Tooltip>
          </h2>
          <p className="mt-1 mb-4 text-gray-700">
            Activate more features for your organisation&apos;s account to
            unlock more functionality.
          </p>
          {extraFeatures.map((feature) => {
            return (
              <div
                key={feature}
                className="flex items-center border-b border-gray-200 py-2"
              >
                <Switch
                  name={feature}
                  onChange={(checked) =>
                    handleFeatureChange({ name: feature, checked })
                  }
                  type="checkbox"
                  checked={features.includes(feature)}
                  className="mr-4"
                />
                <div>
                  <p className="my-0 font-medium">{details[feature].name}</p>
                  <p className="text-gray-500 text-sm my-0">
                    {details[feature].description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeatureSelection;
