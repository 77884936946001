export const sortAlphabetically = (a, b) => {
  if (typeof a !== "string" || typeof b !== "string") {
    a = a.toString();
    b = b.toString();
  }
  const aName = a.toLowerCase();
  const bName = b.toLowerCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const reshapeDate = (date) => {
  if (date) {
    if (typeof date.toDate === "function") {
      return date.toDate();
    }
    return new Date(date);
  }
};

export const stringValueExists = (value) =>
  typeof value === "string" && value.trim().length > 0;

export const cleanString = (value) => value.replaceAll(/\s+/g, " ").trim();

export default {
  sortAlphabetically,
  reshapeDate,
  stringValueExists,
  cleanString,
};
