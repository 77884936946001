import fire, { BUILD_LEVEL } from "../fire";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { stringValueExists } from "./utilities";

export const unique = (arr) => Array.from(new Set(arr));

const db = fire.firestore();
const functions = fire.functions();

export const timestampClean = (data, format) => {
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Timestamp) {
      if (format) {
        data[key] = moment(data[key].toDate()).format(format);
      } else {
        data[key] = data[key].toDate();
      }
    }
  });
  return data;
};

/**
 * For export we create a mapping of name: Value, but as the names can be non-unique
 * this function generates a map of { name: ["name_1", "name_2"] } from the schema.
 *
 * @param {{ [key: string]: string }} schemaLookup - The full schema mapping.
 * @param {string[]} extantKeys - Keys that actually exist within the data.
 *
 * @returns { { [key: string]: string[] } }
 */
const genUniqueSchemaLookup = (extantKeys, schemaLookup) => {
  const map = {};
  const counts = {};

  extantKeys.forEach((key) => {
    const mappedKey = schemaLookup[key];

    if (mappedKey) {
      if (mappedKey in counts) {
        counts[mappedKey] += 1;
        map[key] = `${mappedKey} ${counts[mappedKey]}`;
      } else {
        map[key] = mappedKey;
        counts[mappedKey] = 1;
      }
    }
  });

  return map;
};

export const existingSchemaKeys = (data) => {
  const existingKeys = data.flatMap((d) => {
    return Object.keys(d).flatMap((key) => {
      if (key && d[key]) {
        if (
          !Array.isArray(d[key]) &&
          typeof d[key] === "object" &&
          !(d[key] instanceof Timestamp) &&
          d[key] !== null
        ) {
          return Object.keys(d[key]);
        } else {
          return key;
        }
      }
    });
  });

  return unique(existingKeys);
};

export const exportData = ({
  data,
  schemaLookup,
  restrictToSchema,
  exclude,
  include,
}) => {
  const returnData = [];
  const extantKeys = existingSchemaKeys(data);
  const uniqueFieldNames = genUniqueSchemaLookup(extantKeys, schemaLookup);

  const shouldInclude = (key) => {
    if (include) {
      return include.includes(key);
    } else if (exclude) {
      return !exclude.includes(key);
    } else if (!include && !exclude) {
      return true;
    }
  };

  // schemaLookup has the format {id: name}
  const processValue = ({ row, rowKey, returnRow, prefix }) => {
    // if it is a nested object, recursively call processValue for each nested key.
    // DEMOGRAPHICS is a nested object
    const mappedSchemaKey = schemaLookup?.[rowKey];
    if (row[rowKey]) {
      if (
        !Array.isArray(row[rowKey]) &&
        typeof row[rowKey] === "object" &&
        !(row[rowKey] instanceof Timestamp) &&
        row[rowKey] !== null
      ) {
        Object.keys(row[rowKey]).forEach((subKey) => {
          if (
            shouldInclude(subKey) &&
            (schemaLookup[subKey] || !restrictToSchema)
          ) {
            processValue({
              row: row[rowKey],
              rowKey: subKey,
              returnRow,
              prefix: rowKey,
            });
          }
        });
      } else if (
        row[rowKey] instanceof Timestamp &&
        (schemaLookup[rowKey] || !restrictToSchema)
      ) {
        if (mappedSchemaKey) {
          const fieldName = uniqueFieldNames[rowKey];
          returnRow[fieldName] = moment(row[rowKey].toDate()).format(
            "DD/MM/YYYY"
          );
        } else {
          returnRow[rowKey] = moment(row[rowKey].toDate()).format("DD/MM/YYYY");
        }
      } else if (mappedSchemaKey) {
        const fieldName = uniqueFieldNames[rowKey];
        // get string fieldName from schemaLookup - rowKey is the random firebase id of the field
        returnRow[fieldName] = row[rowKey];
      } else if (prefix && prefix !== "DEMOGRAPHICS" && prefix !== "profile") {
        returnRow[`${prefix}-${rowKey}`] = row[rowKey];
      } else if (!restrictToSchema) {
        returnRow[rowKey] = row[rowKey];
      }
    }
  };

  data.forEach((row) => {
    // will be filled in with correct field names read from schema
    const returnRow = {};
    if (row) {
      // row is a person object and rowKey is a property on that person

      Object.keys(row).forEach((rowKey) => {
        if (shouldInclude(rowKey)) {
          processValue({ row, rowKey, returnRow });
        }
      });

      returnData.push(returnRow);
    }
  });

  return returnData;
};

export const getPeople = (args) => {
  const { conditions, orderBy, limit, managedBy, range, notExisting } = args;
  if (managedBy && !notExisting) {
    let query = db
      .collection("PersonalData")
      .where("managedBy", "==", managedBy);
    if (conditions) {
      Object.keys(conditions).forEach((field) => {
        query = query.where(field, "==", conditions[field]);
      });
    }
    if (range) {
      if (range.lessThan) {
        query = query.where(range.lessThan.field, "<", range.lessThan.value);
      }
      if (range.greaterThan) {
        query = query.where(
          range.greaterThan.field,
          ">",
          range.greaterThan.value
        );
      }
    }
    if (orderBy) {
      query = query.orderBy(orderBy.field, orderBy.type);
    }
    if (limit) {
      query = query.limit(limit);
    }
    return query
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => ({ ...doc.data(), _id: doc.id }))
      );
  } else if (managedBy && notExisting) {
    const basicSearch = functions.httpsCallable("elastic-basicSearch");
    return basicSearch({
      size: 100,
      index: BUILD_LEVEL === "production" ? "members" : "staging-members",
      query: {
        bool: {
          should: notExisting.map((field) => ({
            bool: {
              must_not: {
                exists: {
                  field,
                },
              },
            },
          })),
          filter: {
            bool: {
              filter: [
                {
                  bool: {
                    must_not: {
                      term: {
                        DEMO: true,
                      },
                    },
                  },
                },
                {
                  term: {
                    managedBy: managedBy,
                  },
                },
              ],
            },
          },
        },
      },
    }).then((result) => {
      return result.data.hits.hits.map((item) => ({
        ...item._source,
        _id: item._id,
      }));
    });
  } else {
    return Promise.reject("No organisation ID provided");
  }
};

export const getMemberName = (member) => {
  let fullName = "Member";

  if (Array.isArray(member?.["Full Name"]) && member?.["Full Name"].length > 0 && stringValueExists(member?.["Full Name"][0])) {
    fullName = member["Full Name"][0];
  } else if (stringValueExists(member?.orgName)) {
    fullName = member.orgName
  } else if (stringValueExists(member?.name)) {
    fullName = member.name;
  } else if (stringValueExists(member?.["Full Name"])) {
    fullName = member?.["Full Name"];
  }

  return fullName;
};
