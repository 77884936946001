import {
  getDoc,
  updateDoc,
  getDocs,
  collection,
  query,
  where,
  doc,
  writeBatch,
  arrayUnion,
} from "firebase/firestore";
import {
  ORGANISATIONS_COLLECTION,
  OUTCOMES_COLLECTION,
  TAGS_COLLECTION,
} from "../constants/collections";
import { cleanString, stringValueExists } from "./utilities";
import fire from "../fire";

const db = fire.firestore();

export const loadOrganisation = async (orgId) => {
  const orgDoc = await getDoc(doc(db, ORGANISATIONS_COLLECTION, orgId));
  if (orgDoc.exists()) {
    return { ...orgDoc.data(), _id: orgDoc._id };
  }
  return null;
};

export const loadOrganisations = async (orgIds) => {
  const existingOrgIds = orgIds.filter((orgId) => stringValueExists(orgId));
  const uniqueOrgIds = [...new Set(existingOrgIds)];
  const orgs = await Promise.all(uniqueOrgIds.map((orgId) => loadOrganisation(orgId)));
  return orgs.filter((org) => org !== null);
};

export const addOrganisationTag = async (orgId, tagName, tagDescription) => {
  const cleanTag = cleanString(tagName);
  const batch = writeBatch(db);
  batch.update(doc(db, ORGANISATIONS_COLLECTION, orgId), {
    tags: arrayUnion(cleanTag),
  });
  batch.set(doc(collection(db, TAGS_COLLECTION)), {
    name: cleanTag,
    description: tagDescription,
    managedBy: orgId,
  });
  await batch.commit();
};

export const addAllOrganisationTags = async (orgId, tags) => {
  const batch = writeBatch(db);
  batch.update(doc(db, ORGANISATIONS_COLLECTION, orgId), {
    tags: arrayUnion(...tags.map((tag) => tag.name)),
  });
  tags.forEach((tag) => {
    batch.set(doc(collection(db, TAGS_COLLECTION)), {
      ...tag,
      managedBy: orgId,
    });
  });
  await batch.commit();
};

export const updateOrganisation = async (orgId, data) => {
  await updateDoc(doc(db, ORGANISATIONS_COLLECTION, orgId), data);
};

export const addFeature = async (orgId, feature) => {
  await updateOrganisation(orgId, { features: arrayUnion(feature) });
};

export const loadOrgOutcomes = async (orgId) => {
  const outcomeDocs = await getDocs(
    query(collection(db, OUTCOMES_COLLECTION), where("managedBy", "==", orgId))
  );
  return outcomeDocs.docs.map((rawDoc) => ({ ...rawDoc.data(), _id: rawDoc.id }));
};

export default {
  loadOrganisation,
  loadOrgOutcomes,
};
